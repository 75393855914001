var lpDomain = siteObj.props.testApps.lp_domain;

if (siteObj.props.testApps.lp_domain == 'ED' && siteObj.tplPrefix == 'ed-widgets'){ // show on editorial widget pages if exante
	
  if ($('.lp-panel').length === 0) {
    $('body.ed-widgets .middle-rail #content-page-content .line:last-child').children().wrap('<div class="unit size-3of5"></div>').parent().parent().append('<div class="last-unit unit size-2of5"><div class="lp-panel"><!-- BEGIN LP Button Code --><div id="lpButtonDiv"></div><!--END LP Button Code --></div></div>');
  }	
}

if (siteObj.props.testApps.lp_domain == 'ED' && siteObj.tplPrefix == 'ed-noproducts' ){ // show on editorial no product pages if exante
  if ($('.lp-panel').length === 0) {
    $('body.editorial .middle-rail #content-page-content .get-started').children().wrap('<div class="unit size-3of5"></div>').parent().parent().append('<div class="last-unit unit size-2of5"><div class="lp-panel"><!-- BEGIN LP Button Code --><div id="lpButtonDiv"></div><!--END LP Button Code --></div></div>');
  }
}

if (siteObj.props.testApps.lp_domain == 'ED' && siteObj.tplPrefix == 'multi-widget' ){ // show on editorial no product pages if exante
  if ($('.lp-panel').length === 0) {
    $('body.multi-widget .editorial-page .live-person').children().append('<!-- BEGIN LP Button Code --><div id="lpButtonDiv"></div><!--END LP Button Code -->');
  }
}

var lpMTagConfig=lpMTagConfig||{};lpMTagConfig.vars=lpMTagConfig.vars||[];lpMTagConfig.dynButton=lpMTagConfig.dynButton||[];lpMTagConfig.lpProtocol=document.location.toString().indexOf('https:')==0?'https':'http';lpMTagConfig.pageStartTime=(new Date).getTime();if(!lpMTagConfig.pluginsLoaded)lpMTagConfig.pluginsLoaded=!1;
lpMTagConfig.loadTag=function(){for(var a=document.cookie.split(';'),b={},c=0;c<a.length;c++){var d=a[c].substring(0,a[c].indexOf('='));b[d.replace(/^\s+|\s+$/g,'')]=a[c].substring(a[c].indexOf('=')+1)}a=b.HumanClickRedirectOrgSite;b=b.HumanClickRedirectDestSite;if(!lpMTagConfig.pluginsLoaded)lpMTagConfig.pageLoadTime=(new Date).getTime()-lpMTagConfig.pageStartTime,b='?site='+(a==lpMTagConfig.lpNumber?b:lpMTagConfig.lpNumber)+'&d_id='+lpMTagConfig.deploymentID+'&default=simpleDeploy',lpAddMonitorTag(lpMTagConfig.deploymentConfigPath!=
null?lpMTagConfig.lpProtocol+'://'+lpMTagConfig.deploymentConfigPath+b:lpMTagConfig.lpProtocol+'://'+lpMTagConfig.lpTagSrv+'/visitor/addons/deploy2.asp'+b),lpMTagConfig.pluginsLoaded=!0};
function lpAddMonitorTag(a){if(!lpMTagConfig.lpTagLoaded){if(typeof a=='undefined'||typeof a=='object')a=lpMTagConfig.lpMTagSrc?lpMTagConfig.lpMTagSrc:lpMTagConfig.lpTagSrv?lpMTagConfig.lpProtocol+'://'+lpMTagConfig.lpTagSrv+'/hcp/html/mTag.js':'/hcp/html/mTag.js';a.indexOf('http')!=0?a=lpMTagConfig.lpProtocol+'://'+lpMTagConfig.lpServer+a+'?site='+lpMTagConfig.lpNumber:a.indexOf('site=')<0&&(a+=a.indexOf('?')<0?'?':'&',a=a+'site='+lpMTagConfig.lpNumber);var b=document.createElement('script');b.setAttribute('type',
  'text/javascript');b.setAttribute('charset','iso-8859-1');b.setAttribute('src',a);document.getElementsByTagName('head').item(0).appendChild(b)}}
(function(){lpMTagConfig.containsUnit=!1;lpMTagConfig.containsLanguage=!1;for(var a=0;a<lpMTagConfig.vars.length;a++){var b=null;lpMTagConfig.vars[a].length==2?b=lpMTagConfig.vars[a][0]:lpMTagConfig.vars[a].length>2&&(b=lpMTagConfig.vars[a][1]);switch(b){case 'unit':lpMTagConfig.containsUnit=!0;break;case 'language':lpMTagConfig.containsLanguage=!0}}})();
function lpSendData(a,b,c){if(arguments.length>0)lpMTagConfig.vars=lpMTagConfig.vars||[],lpMTagConfig.vars.push([a,b,c]);if(typeof lpMTag!='undefined'&&typeof lpMTagConfig.pluginCode!='undefined'&&typeof lpMTagConfig.pluginCode.simpleDeploy!='undefined'){var d=lpMTagConfig.pluginCode.simpleDeploy.processVars();lpMTag.lpSendData(d,!0)}}function lpAddVars(a,b,c){lpMTagConfig.vars=lpMTagConfig.vars||[];lpMTagConfig.vars.push([a,b,c])}

lpMTagConfig.lpTagSrv = 'sr4.liveperson.net'; lpMTagConfig.lpServer = '	server.lon.liveperson.net'; 
lpMTagConfig.lpNumber = '17245003'; lpMTagConfig.deploymentID = '1';
	
if (typeof(lpDomain)=='undefined') { var lpDomain='general'; }
lpMTagConfig.vars.push(['session','domain',lpDomain]);

if (typeof(lpUnit)=='undefined') { var lpUnit='sales'; }
if (!lpMTagConfig.containsUnit) {
  lpMTagConfig.vars.push(['page','unit',(window.lpUnit || lpUnit)]);
}

if (typeof(lpLanguage)=='undefined')	var lpLanguage='EN';
if (!lpMTagConfig.containsLanguage) {
  lpMTagConfig.vars.push(['session','language',lpLanguage]);
}	

var basketTotalValue = siteObj.basketTotalValue;
var currencyType = basketTotalValue.match('&[^\s]*;');
if (currencyType == null){
  basketTotalValue = basketTotalValue.replace('$','');
} else if (currencyType != null){
  basketTotalValue = basketTotalValue.replace(currencyType,'');
} else {
}
	
var currencyTypeChars = siteObj.currencyType;

if (siteObj.page === 'login'){
  //variables sent on page load
  lpMTagConfig.vars.push(['page','Section','Checkout']); 
  //Return 'General' on all pages (except for checkout). 
  //On checkout pages return 'Checkout'
  lpMTagConfig.vars.push(['page','Currency',currencyTypeChars]); 
  //[GBP, EUR] Return value of selected currency
  lpMTagConfig.vars.push(['page','CartTotal',basketTotalValue]); 
  //Return value of cart before order confirmation
  lpMTagConfig.vars.push(['page','ConversionStage','1']);  
  //If Section = General: don't pass this variable
  //If Section = Checkout: Returns numeric step in process [1 (Login), 2 (Registration), 3 (Details), 4 (Confirmation)]
  //Basket page does not have a ConversionStage 
  lpMTagConfig.vars.push(['page','ErrorCounter','0']); 
  //If Section = Checkout: Pass number of errors appearing on current page
  //If Section = General: don't pass this variable
  lpMTagConfig.vars.push(['page','ErrorName','0']); 
  //If Section = Checkout: Pass all visitor data entry errors as comma delimited xml string

} else if (siteObj.page == 'basket'){

  //variables sent on page load
  lpMTagConfig.vars.push(['page','Section','Checkout']); 
  //Return 'General' on all pages (except for checkout). 
  //On checkout pages return 'Checkout'
  lpMTagConfig.vars.push(['page','Currency',currencyTypeChars]); 
  //[GBP, EUR] Return value of selected currency
  lpMTagConfig.vars.push(['page','CartTotal',basketTotalValue]); 
  //Return value of cart before order confirmation
  lpMTagConfig.vars.push(['page','ConversionStage','1']);  
  //If Section = General: don't pass this variable
  //If Section = Checkout: Returns numeric step in process [1 (Login), 2 (Registration), 3 (Details), 4 (Confirmation)]
  //Basket page does not have a ConversionStage 
  lpMTagConfig.vars.push(['page','ErrorCounter','0']); 
  //If Section = Checkout: Pass number of errors appearing on current page
  //If Section = General: don't pass this variable
  lpMTagConfig.vars.push(['page','ErrorName','0']); 
  //If Section = Checkout: Pass all visitor data entry errors as comma delimited xml string

} else if (siteObj.page == 'account-creation'){

  //variables sent on page load
  lpMTagConfig.vars.push(['page','Section','Checkout']); 
  //Return 'General' on all pages (except for checkout). 
  //On checkout pages return 'Checkout'
  lpMTagConfig.vars.push(['page','Currency',currencyTypeChars]); 
  //[GBP, EUR] Return value of selected currency
  lpMTagConfig.vars.push(['page','CartTotal',basketTotalValue]); 
  //Return value of cart before order confirmation
  lpMTagConfig.vars.push(['page','ConversionStage','2']);  
  //If Section = General: don't pass this variable
  //If Section = Checkout: Returns numeric step in process [1 (Login), 2 (Registration), 3 (Details), 4 (Confirmation)]
  //Basket page does not have a ConversionStage 
  lpMTagConfig.vars.push(['page','ErrorCounter','0']); 
  //If Section = Checkout: Pass number of errors appearing on current page
  //If Section = General: don't pass this variable
  lpMTagConfig.vars.push(['page','ErrorName','0']); 
  //If Section = Checkout: Pass all visitor data entry errors as comma delimited xml string

} else if (siteObj.page == 'customer-service'){

  //variables sent on page load
  lpMTagConfig.vars.push(['page','Section','CustomerQuery']); 
  //Return 'General' on all pages (except for checkout). 
  //On checkout pages return 'Checkout'
  lpMTagConfig.vars.push(['page','Currency',currencyTypeChars]); 
  //[GBP, EUR] Return value of selected currency
  lpMTagConfig.vars.push(['page','CartTotal',basketTotalValue]); 
  //Return value of cart before order confirmation

} else if (siteObj.page == 'account-home' || siteObj.page == 'account-order' || siteObj.page == 'account-order-history' || siteObj.page == 'account-reward-points' || siteObj.page == 'account-settings' || siteObj.page == 'account-address-book' || siteObj.page == 'account-address' || siteObj.page == 'account-payment-details' || siteObj.page == 'account-card' || siteObj.page == 'account-credit'){

  //variables sent on page load
  lpMTagConfig.vars.push(['page','Section','MyAccount']); 
  //Return 'General' on all pages (except for checkout). 
  //On checkout pages return 'Checkout'
  lpMTagConfig.vars.push(['page','Currency',currencyTypeChars]); 
  //[GBP, EUR] Return value of selected currency
  lpMTagConfig.vars.push(['page','CartTotal',basketTotalValue]); 
  //Return value of cart before order confirmation

} else {
	
  //variables sent on page load
  lpMTagConfig.vars.push(['page','Section','General']); 
  //Return 'General' on all pages (except for checkout). 
  //On checkout pages return 'Checkout'
  lpMTagConfig.vars.push(['page','Currency',currencyTypeChars]); 
  //[GBP, EUR] Return value of selected currency
  lpMTagConfig.vars.push(['page','CartTotal',basketTotalValue]); 
  //Return value of cart before order confirmation

}
	

lpMTagConfig.loadTag();
